import * as Sentry from "@sentry/react"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const NODE_ENV = import.meta.env.NODE_ENV

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [],
    environment: NODE_ENV,
  })
}
