import commonES from "./es/common.json"
import commonEN from "./en/common.json"
import settingsES from "./es/settings.json"
import settingsEN from "./en/settings.json"
import dashboardES from "./es/dashboard.json"
import dashboardEN from "./en/dashboard.json"
import glossaryES from "./es/glossary.json"
import glossaryEN from "./en/glossary.json"
import walletES from "./es/wallet.json"
import walletEN from "./en/wallet.json"
import loginES from "./es/login.json"
import loginEN from "./en/login.json"
import liquidityES from "./es/liquidity.json"
import liquidityEN from "./en/liquidity.json"
import poolES from "./es/pool.json"
import poolEN from "./en/pool.json"
import swapES from "./es/swap.json"
import swapEN from "./en/swap.json"
import stakingES from "./es/staking.json"
import stakingEN from "./en/staking.json"
import buyES from "./es/buy.json"
import buyEN from "./en/buy.json"
import syntheticsES from "./es/synthetics.json"
import syntheticsEN from "./en/synthetics.json"
import migrationES from "./es/migration.json"
import migrationEN from "./en/migration.json"
import errorES from "./es/error.json"
import errorEN from "./en/error.json"

export const es = {
  common: commonES,
  wallet: walletES,
  settings: settingsES,
  dashboard: dashboardES,
  login: loginES,
  glossary: glossaryES,
  liquidity: liquidityES,
  pool: poolES,
  swap: swapES,
  staking: stakingES,
  buy: buyES,
  synthetics: syntheticsES,
  migration: migrationES,
  error: errorES,
}

export const en = {
  common: commonEN,
  wallet: walletEN,
  settings: settingsEN,
  dashboard: dashboardEN,
  login: loginEN,
  glossary: glossaryEN,
  liquidity: liquidityEN,
  pool: poolEN,
  swap: swapEN,
  staking: stakingEN,
  buy: buyEN,
  synthetics: syntheticsEN,
  migration: migrationEN,
  error: errorEN,
}
