import React, { createContext, useState, useEffect, ReactNode, useContext, useCallback } from "react"
import { useEthereum } from "./EthereumContext"
import { DomainEventDetail, DomainEvents } from "../domain/_kernel/Events"
import { Transaction } from "../domain/ethereum/Models/Transaction"
import { WalletState } from "../domain/ethereum/Models/WalletState"
import { UserBalances } from "../domain/user/Models/UserBalances"
import { PoolStatsList } from "../domain/pool/Models/PoolStatsList"
import { UserPoolStatsList } from "../domain/pool/Models/UserPoolStatsList"
import { HistoricalStats } from "../domain/synthetic/Models/HistoricalStats"
import { EpochPoolStatsList } from "../domain/synthetic/Models/EpochPoolStatsList"
import { EpochInfo } from "../domain/synthetic/Models/EpochInfo"
import { UserAirdropRewards } from "../domain/user/Models/UserAirdropRewards"
import { UserSyntheticDepositList } from "../domain/synthetic/Models/UserSyntheticDepositList"
import { HistoricalLiquidityRewards } from "../domain/pool/Models/HistoricalLiquidityRewards"
import { HistoricalEpochRewards } from "../domain/synthetic/Models/HistoricalEpochRewards"

type DataContextInterface = {
  currentEpochPoolStats: EpochPoolStatsList | null
  epochInfo: EpochInfo | null
  historicalStats: HistoricalStats | null
  historicalLiquidityRewards: HistoricalLiquidityRewards | null
  historicalEpochRewards: HistoricalEpochRewards | null
  poolStatsList: PoolStatsList | null
  userAirdropRewards: UserAirdropRewards | null
  userBalances: UserBalances | null
  userPoolStatsList: UserPoolStatsList | null
  userDeposits: UserSyntheticDepositList | null
  isWaitingForNewNFT: boolean
}

const initialState: DataContextInterface = {
  currentEpochPoolStats: null,
  epochInfo: null,
  historicalStats: null,
  historicalLiquidityRewards: null,
  historicalEpochRewards: null,
  poolStatsList: null,
  userAirdropRewards: null,
  userBalances: null,
  userPoolStatsList: null,
  userDeposits: null,
  isWaitingForNewNFT: false,
}

type DataProviderProps = {
  children: ReactNode
}

const Context = createContext<DataContextInterface>(initialState)

export const DataContext: React.FC<DataProviderProps> = ({ children }) => {
  const { wallet, waitForNextBlockNumber } = useEthereum()

  const [currentEpochPoolStats, setCurrentEpochPoolStats] = useState<EpochPoolStatsList | null>(null)
  const [epochInfo, setEpochInfo] = useState<EpochInfo | null>(null)
  const [historicalStats, setHistoricalStats] = useState<HistoricalStats | null>(null)
  const [historicalLiquidityRewards, setHistoricalLiquidityRewards] = useState<HistoricalLiquidityRewards | null>(null)
  const [historicalEpochRewards, setHistoricalEpochRewards] = useState<HistoricalEpochRewards | null>(null)
  const [poolStatsList, setPoolStatsList] = useState<PoolStatsList | null>(null)
  const [userAirdropRewards, setUserAirdropRewards] = useState<UserAirdropRewards | null>(null)
  const [userBalances, setUserBalances] = useState<UserBalances | null>(null)
  const [userPoolStatsList, setUserPoolStatsList] = useState<UserPoolStatsList | null>(null)
  const [userDeposits, setUserDeposits] = useState<UserSyntheticDepositList | null>(null)
  const [isWaitingForNewNFT, setIsWaitingForNewNFT] = useState(false)

  const updatePoolStatsList = () =>
    window.domain.GetPoolStatsListUseCase.execute().then(setPoolStatsList).catch(console.error)

  const updateUserBalances = () =>
    window.domain.GetBalancesUserUseCase.execute().then(setUserBalances).catch(console.error)

  const updateUserPoolStatsList = () =>
    window.domain.GetUserPoolStatsListUseCase.execute().then(setUserPoolStatsList).catch(console.error)

  const updateHistoricalStats = () =>
    window.domain.GetHistoricalStatsSyntheticUseCase.execute().then(setHistoricalStats).catch(console.error)

  const updateHistoricalLiquidityRewards = () =>
    window.domain.GetHistoricalLiquidityRewardsPoolUseCase.execute()
      .then(setHistoricalLiquidityRewards)
      .catch(console.error)

  const updateHistoricalEpochRewards = () =>
    window.domain.GetHistoricalEpochRewardsSyntheticUseCase.execute()
      .then(setHistoricalEpochRewards)
      .catch(console.error)

  const updateEpochInfo = () =>
    window.domain.GetCurrentEpochInfoSyntheticUseCase.execute().then(setEpochInfo).catch(console.error)

  const updateUserAirdropRewards = () =>
    window.domain.GetAirdropRewardsUserUseCase.execute().then(setUserAirdropRewards).catch(console.error)

  const updateUserSyntheticDepositsSynthetic = () =>
    window.domain.GetUserSyntheticDepositsSyntheticUseCase.execute().then(setUserDeposits).catch(console.error)

  const updateCurrentEpochPoolStats = () =>
    window.domain.GetCurrentEpochPoolStatsListSyntheticUseCase.execute()
      .then(setCurrentEpochPoolStats)
      .catch(console.error)

  const waitForNewDeposit = useCallback(() => {
    setIsWaitingForNewNFT(true)
    waitForNextBlockNumber().then(() =>
      updateUserSyntheticDepositsSynthetic().finally(() => setIsWaitingForNewNFT(false)),
    )
  }, [waitForNextBlockNumber])

  // Initial load
  useEffect(() => {
    if (wallet.status !== WalletState.STATUS.CONNECTED) return

    Promise.all([
      updatePoolStatsList(),
      updateUserBalances(),
      updateUserPoolStatsList(),
      updateHistoricalStats(),
      updateHistoricalLiquidityRewards(),
      updateHistoricalEpochRewards(),
      updateCurrentEpochPoolStats(),
      updateEpochInfo(),
      updateUserAirdropRewards(),
      updateUserSyntheticDepositsSynthetic(),
    ])
  }, [wallet.status])

  // Update data
  const updateData = useCallback(
    (transaction: Transaction) => {
      switch (transaction.type) {
        case Transaction.TYPES.ADD_LIQUIDITY:
        case Transaction.TYPES.STAKE_LIQUIDITY:
        case Transaction.TYPES.UNSTAKE_LIQUIDITY:
        case Transaction.TYPES.WITHDRAW_LIQUIDITY:
          updatePoolStatsList()
          updateUserBalances()
          updateUserPoolStatsList()
          break
        case Transaction.TYPES.CLAIM_POOL:
          updateUserBalances()
          updateUserPoolStatsList()
          break
        case Transaction.TYPES.CLAIM_REWARD:
          updateUserAirdropRewards()
          updateUserBalances()
          break
        case Transaction.TYPES.SWAP:
          updateUserBalances()
          break
        case Transaction.TYPES.VOTE:
        case Transaction.TYPES.ADD_INCENTIVE:
          updateCurrentEpochPoolStats()
          break
        case Transaction.TYPES.DEPOSIT_STAKE:
          waitForNewDeposit()
          break
        case Transaction.TYPES.INCREASE_STAKE:
        case Transaction.TYPES.WITHDRAW_STAKE:
        case Transaction.TYPES.REBUILD_STAKE:
          updateUserSyntheticDepositsSynthetic()
          break
        case Transaction.TYPES.CLOSE_EPOCH:
          updateEpochInfo()
          break
      }
    },
    [waitForNewDeposit],
  )

  // Success events
  useEffect(() => {
    const eventHandler = (e: CustomEvent<DomainEventDetail[DomainEvents.SUCCESS_TRANSACTION]>) => {
      updateData(e.detail.transaction)
    }

    window.addEventListener(DomainEvents.SUCCESS_TRANSACTION, eventHandler as EventListener)
    return () => window.removeEventListener(DomainEvents.SUCCESS_TRANSACTION, eventHandler as EventListener)
  }, [updateData])

  return (
    <Context.Provider
      value={{
        currentEpochPoolStats,
        epochInfo,
        historicalStats,
        historicalLiquidityRewards,
        historicalEpochRewards,
        poolStatsList,
        userAirdropRewards,
        userBalances,
        userPoolStatsList,
        userDeposits,
        isWaitingForNewNFT,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useData = function () {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error(`useData must be used within a DataContextProvider`)
  }
  return context
}
