import debug from "debug"

export const createLogger = (namespace: string) => {
  const logger = debug(`wa:${namespace}`)
  return {
    log: logger,
    error: logger.extend("error"),
    warn: logger.extend("warn"),
    info: logger.extend("info"),
  }
}
