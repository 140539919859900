import React from "react"
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom"
import { DomainContext, EthereumContext, LayoutContext, TransactionsContext } from "./context"
import { DataContext } from "./context/DataContext"
import "./i18n.config"
import "./index.css"
import { LoadingBar } from "@tutellus/tutellus-components"
import { Component as ErrorPage } from "./pages/Error"
import { initPWA } from "./js/pwa"
import { initSentry } from "./js/sentry"
// import { createLogger } from "./js/pwa/logger"
// import { UpdateNotification } from "./components/modules/UpdateNotification"

initSentry()

// const logger = createLogger("app")

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="migration" id="migration" lazy={async () => import("./pages/Migration")} />
      <Route
        element={
          <DataContext>
            <Outlet />
          </DataContext>
        }
        errorElement={<ErrorPage />}
      >
        <Route path="connect" id="connect" lazy={async () => import("./pages/Connect")} />
        <Route id="root" lazy={async () => import("./pages/Root")}>
          <Route index lazy={async () => import("./pages/Dashboard")} />
          <Route path="swap" lazy={async () => import("./pages/Swap")} />
          <Route path="wallet" lazy={async () => import("./pages/Wallet")} />
          <Route path="buy" lazy={async () => import("./pages/Buy")} />
          <Route path="liquidity" lazy={async () => import("./pages/Liquidity")} />
          <Route path="liquidity/:poolID" lazy={async () => import("./pages/PoolRoot")}>
            <Route path="deposit" lazy={async () => import("./pages/PoolDeposit")} />
            <Route path="withdraw" lazy={async () => import("./pages/PoolWithdraw")} />
          </Route>
          <Route path="vote/:syntheticID" id="staking-root" lazy={async () => import("./pages/StakingRoot")}>
            <Route index element={<Navigate to="information" />} />
            <Route path="information" lazy={async () => import("./pages/StakingInfo")} />
            <Route path="voting" lazy={async () => import("./pages/StakingVotes")} />
            <Route path="generate" lazy={async () => import("./pages/StakingGenerate")} />
            <Route path="rewards" lazy={async () => import("./pages/StakingRewards")} />
          </Route>
          <Route path="admin" id="admin-root" lazy={async () => import("./pages/AdminRoot")}>
            <Route index element={<Navigate to="close-epoch" replace />} />
            <Route path="close-epoch" lazy={async () => import("./pages/AdminCloseEpoch")} />
            <Route path="add-incentives" lazy={async () => import("./pages/AdminAddIncentives")} />
            <Route path="historical" lazy={async () => import("./pages/AdminHistorical")} />
          </Route>
        </Route>
      </Route>
    </>,
  ),
  {
    basename: "/",
  },
)

const App = () => {
  // const [updateSW, setUpdateSW] = useState<(() => Promise<void>) | null>(null)

  // useEffect(() => {
  //   logger.log("Registering service worker...")
  //   const registration = initPWA()
  //   if (registration) {
  //     logger.log("Service worker registration available")
  //     setUpdateSW(() => registration)
  //   } else {
  //     logger.warn("No service worker registration available")
  //   }
  // }, [])

  return (
    <React.StrictMode>
      <DomainContext>
        <EthereumContext>
          <TransactionsContext>
            <LayoutContext>
              <>
                <RouterProvider
                  router={router}
                  fallbackElement={
                    <LoadingBar
                      mode="dark"
                      isFullScreen
                      icon={
                        <img
                          src="https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970ea733a82db5bc5c0a_gt3-iso.svg"
                          alt="Loading"
                        />
                      }
                    />
                  }
                />
                {/* {updateSW && <UpdateNotification onUpdate={updateSW} />} */}
              </>
            </LayoutContext>
          </TransactionsContext>
        </EthereumContext>
      </DomainContext>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />)

initPWA()
