import { createPublicClient, http } from "viem"
import { CHAIN_CONFIG } from "../chain"

const RPC_HTTP = import.meta.env.VITE_RPC_HTTP

export const publicClient = createPublicClient({
  chain: CHAIN_CONFIG.viemConfig,
  pollingInterval: CHAIN_CONFIG.pollingInterval,
  transport: http(RPC_HTTP),
})
