import { createConfig, http } from "@wagmi/core"
import { walletConnect } from "@wagmi/connectors"
import { CHAIN_CONFIG } from "../chain"

const RPC_HTTP = import.meta.env.VITE_RPC_HTTP
const WALLETCONNECT_PROJECT_ID = import.meta.env.VITE_WALLETCONNECT_PROJECT_ID

export const wagmiConfig = createConfig({
  chains: [CHAIN_CONFIG.viemConfig],
  connectors: [
    walletConnect({
      projectId: WALLETCONNECT_PROJECT_ID,
    }),
  ],
  transports: {
    [CHAIN_CONFIG.id]: http(RPC_HTTP),
  },
})
